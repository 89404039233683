import { graphql } from 'gatsby';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Sitemap from '../../components/Page/Sitemap';
import Headline from '../../components/Headline';
import Layout from '../../components/Layout';
import SEO, { createAlternateLinks } from '../../components/Seo';

const messages = defineMessages({
  title: {
    id: 'sitemapPage.title',
    defaultMessage: 'Sitemap',
  },
});

const SitemapPage = ({
  data: { allSitePage, site },
  location: { pathname },
  pageContext: {
    intl: { originalPath },
  },
}) => {
  const { locale, formatMessage } = useIntl();
  const alternateLinks = createAlternateLinks(originalPath);

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage(messages.title)}
        pathname={pathname}
        link={alternateLinks}
      />
      <Layout originalPath={originalPath}>
        <Headline title={formatMessage(messages.title)} />
        <Sitemap
          allPages={allSitePage.edges}
          host={site.siteMetadata.siteUrl}
        />
      </Layout>
    </>
  );
};

SitemapPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query SitemapPageQuery {
    allSitePage(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          path
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default SitemapPage;
