import { Link } from 'gatsby';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Section from '../../Grid/Section';
import * as s from './Sitemap.module.css';

const messages = defineMessages({
  pagesTitle: {
    id: 'sitemapPage.pagesTitle',
    defaultMessage: 'Pages',
  },
  blogsTitle: {
    id: 'sitemapPage.blogsTitle',
    defaultMessage: 'Blogs',
  },
  blogPostsTitle: {
    id: 'sitemapPage.blogPostsTitle',
    defaultMessage: 'Blog posts',
  },
  catalogTitle: {
    id: 'sitemapPage.catalogTitle',
    defaultMessage: 'Catalog',
  },
});

const blogPaths = [
  '/csgo/',
  '/pl/csgo/',
  '/ru/csgo/',
  '/br/csgo/',
  '/de/csgo/',
  '/dota-2/',
  '/ru/dota-2/',
  '/spolka/',
];

const blogSitemapPaths = [
  '/csgo/html-site-map/',
  '/pl/csgo/mapa-strony/',
  '/ru/csgo/html-site-map/',
  '/br/csgo/html-site-map/',
  '/de/csgo/html-site-map/',
  '/dota-2/site-map/',
  '/ru/dota-2/html-site-map/',
  '/spolka/mapa-strony/',
];

const Sitemap = ({ allPages, host }) => {
  const { formatMessage } = useIntl();

  return (
    <Section className={s.root}>
      <h2 className={s.title}>{formatMessage(messages.pagesTitle)}</h2>
      <ul className={s.list}>
        {allPages
          .filter(
            (page) =>
              !page.node.path.includes('404') &&
              !page.node.path.includes('sitemap') &&
              !page.node.path.includes('hub') &&
              !page.node.path.match(
                /(maskedgiveaway|sajugiveaway|tudsongiveaway|stompgiveaway|akarugiveaway|flavorgiveaway)/,
              ) &&
              !page.node.path.match(
                /^\/..\/(giveaways|privacy-policy|terms-of-service)/,
              ),
          )
          .map(({ node }) => (
            <li key={node.id}>
              <Link to={node.path}>
                {host}
                <span>{node.path}</span>
              </Link>
            </li>
          ))}
      </ul>
      <h2 className={s.title}>{formatMessage(messages.catalogTitle)}</h2>
      <ul className={s.list}>
        <li>
          <a href="/sitemap-catalog/">
            {host}
            <span>/sitemap-catalog/</span>
          </a>
        </li>
      </ul>
      <h2 className={s.title}>{formatMessage(messages.blogsTitle)}</h2>
      <ul className={s.list}>
        {blogPaths.map((path) => (
          <li key={path}>
            <a href={path}>
              {host}
              <span>{path}</span>
            </a>
          </li>
        ))}
      </ul>
      <h2 className={s.title}>{formatMessage(messages.blogPostsTitle)}</h2>
      <ul className={s.list}>
        {blogSitemapPaths.map((path) => (
          <li key={path}>
            <a href={path}>
              {host}
              <span>{path}</span>
            </a>
          </li>
        ))}
      </ul>
    </Section>
  );
};

Sitemap.propTypes = {
  allPages: PropTypes.array.isRequired,
  host: PropTypes.string.isRequired,
};

export default Sitemap;
